import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { FirebaseHandler } from "../utility/firebaseHandler";

import { doc, getFirestore, onSnapshot } from "firebase/firestore";

const IsAwayContext = React.createContext();
const IsAwayUpdateContext = React.createContext();
const db = getFirestore();

export const useIsAway = () => {
	return useContext(IsAwayContext);
};

export const useIsAwayUpdate = () => {
	return useContext(IsAwayUpdateContext);
};

const IsAwayProvider = ({ children }) => {
	const [isAway, setIsAway] = useState(false);

	const changeIsAway = async () => {
		let newIsAway = await FirebaseHandler.changeIsAway();

		setIsAway(newIsAway);
	};

	useEffect(() => {
		const shopInfoRef = doc(db, "admin", "shopInfo");

		const unsub = onSnapshot(shopInfoRef, (doc) => {
			setIsAway(doc.data().isAway);
		});
	}, []);

	return (
		<IsAwayContext.Provider value={isAway}>
			<IsAwayUpdateContext.Provider value={changeIsAway}>
				{children}
			</IsAwayUpdateContext.Provider>
		</IsAwayContext.Provider>
	);
};

export default IsAwayProvider;
