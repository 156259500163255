import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, runTransaction, getFirestore } from "firebase/firestore";
import { firebaseConfig } from "./keys";

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();

export const FirebaseHandler = {
	async loginUser(email, password) {
		let returnMsg;
		let success;

		await signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;
				returnMsg = user;
				success = true;
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				returnMsg = errorMessage;
				success = false;
			});

		return [success, returnMsg];
	},

	async changeIsAway() {
		const shopInfoRef = doc(db, "admin", "shopInfo");
		let newIsAway;

		try {
			await runTransaction(db, async (transaction) => {
				const sfDoc = await transaction.get(shopInfoRef);
				if (!sfDoc.exists()) {
					throw "Document does not exist!";
				}

				newIsAway = !sfDoc.data().isAway;
				transaction.update(shopInfoRef, { isAway: newIsAway });
			});
			console.log("Transaction successfully committed!");
		} catch (e) {
			console.log("Transaction failed: ", e);
		}

		return newIsAway;
	},
};
