import React from "react";
import { useState, useRef } from "react";
import classes from "../FileUpload/FileUpload.module.scss";
import Cross from "../../../../resources/images/photos/cross-sign.png";

const FileUpload = () => {
  const [file, setFile] = useState();
  const inputFile = useRef(null);

  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const handleReset = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "file";
      setFile();
    }
  };

  return (
    <div className={classes.FileUploadContainer}>
      <input
        type="file"
        name="attachment"
        accept="image/png, image/jpeg"
        id="fileToUpload"
        onChange={handleChange}
        ref={inputFile}
        hidden
      />
      <label htmlFor="fileToUpload" className={classes.FileUpload}>
        Ladda upp bild
      </label>
      {file != null ? (
        <div className={classes.ImagePreviewContainer}>
          <img src={file} className={classes.ImagePreview} alt="preview" />
          <div className={classes.RemoveImage} onClick={handleReset}>
            <img src={Cross} className={classes.Cross} alt="remove" />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FileUpload;
