import React, { useState } from "react";
import classes from "./mobileNavigation.module.scss";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import varmaGolvLogo from "../../../../resources/images/logos/varma_golv_logo.svg";
import { useHistory } from "react-router-dom";

const MobileNavigation = ({
	navigationButtons,
	navigationButtonClickHandler,
}) => {
	const history = useHistory();

	const [showModal, setShowModal] = useState(false);
	const [modalBackgroundClasses, setModalBackgroundClasses] = useState([
		classes.modalBackground,
		classes.hideModalBackground,
	]);

	const [modalClasses, setModalClasses] = useState([
		classes.modal,
		classes.hideModal,
	]);

	const modalChangeHandler = () => {
		let showModalTemp = !showModal;

		if (showModalTemp) {
			setModalClasses([classes.modal]);
			setModalBackgroundClasses([classes.modalBackground]);
		} else {
			setModalClasses([classes.modal, classes.hideModal]);
			setModalBackgroundClasses([
				classes.modalBackground,
				classes.hideModalBackground,
			]);
		}

		setShowModal(showModalTemp);
	};

	return (
		<>
			<div className={classes.MobileNavigation}>
				<img
					src={varmaGolvLogo}
					alt="Varma Golv logo"
					onClick={() => {
						history.replace("/");
						window.scrollTo(0, 0);
					}}
				/>

				<HamburgerButton
					showingModal={showModal}
					onClick={modalChangeHandler}
				/>
			</div>

			<div className={modalClasses.join(" ")} onClick={modalChangeHandler}>
				{navigationButtons.map((navigationButton) => (
					<button
						key={navigationButton.text}
						className={classes.mobileNavBtn}
						onClick={() => navigationButtonClickHandler(navigationButton.text)}
					>
						{navigationButton.text}
					</button>
				))}
			</div>

			<div
				className={modalBackgroundClasses.join(" ")}
				onClick={() => {
					if (showModal) {
						modalChangeHandler();
					}
				}}
			/>
		</>
	);
};

export default MobileNavigation;
