import React from "react";
import { useState } from "react";
import { useIsAway } from "../../../../../contexts/IsAwayContext";
import SubmitButton from "../../../../common/form/SubmitButton/SubmitButton";
import TextArea from "../../../../common/form/TextArea/TextArea";
import TextInputField from "../../../../common/form/TextInputField/TextInputField";
import classes from "./contactFormSection.module.scss";
import FileUpload from "../../../../common/form/FileUpload/FileUpload";

const ContactFormSection = ({ contactRef }) => {
  const isAway = useIsAway();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");

  const emailChangeHandler = (newEmail) => {
    setEmail(newEmail);
  };

  const addressChangeHandler = (newAddress) => {
    setAddress(newAddress);
  };

  const nameChangeHandler = (newName) => {
    setName(newName);
  };

  const messageChangeHandler = (newMessage) => {
    setMessage(newMessage);
  };

  return (
    <div className={classes.ContactFormSection} ref={contactRef}>
      <div className={classes.ContactFormContent}>
        <form
          className={classes.contactForm}
          action="https://formsubmit.co/info@varmagolv.se"
          method="POST"
          encType="multipart/form-data"
        >
          <TextInputField
            title="Mailaddress"
            onTextChange={emailChangeHandler}
            type="email"
            name="Mail"
            text={email}
          />

          <TextInputField
            title="Namn"
            onTextChange={nameChangeHandler}
            name="Namn"
            text={name}
          />

          <TextInputField
            title="Adress"
            onTextChange={addressChangeHandler}
            name="Adress"
            text={address}
          />

          <TextArea
            title="Meddelande"
            onTextChange={messageChangeHandler}
            text={message}
            name="Meddelande"
          />
          <FileUpload />
          <SubmitButton text="Skicka" />
        </form>

        <div className={classes.contactAndInfo}>
          <h2>Öppetider och kontakt</h2>
          <p>
            Om du har funderingar kring offert, konsultation eller har andra
            frågor – tveka inte att kontakta oss! Du når oss lättast via
            webbformuläret eller telefon. Vill du besöka oss är du välkommen
            till vår butik på Fågelsången 3 i Sundbyberg. Vi är inte alltid inne
            i butiken, ring innan för att boka ditt besök!
            <br />
            <br />
            <b>Besöks- och postadress: </b>Fågelsången 3, 172 32 Sundbyberg
            <br />
            <br />
            <b>Telefon, Jörgen: </b>070-715 20 72
            <br />
            <b>Telefon, Ludvig: </b>073-715 22 98
            <br />
            <b>E-post: </b>info@varmagolv.se
            <br />
            <b>Öppetider: </b>Måndag–Fredag, 07:00–17:00
            <br />
            <br />
            {isAway ? (
              <span className={classes.alert}>
                Just nu är vi ute på uppdrag, ring oss om ni vill handla i
                butiken!
              </span>
            ) : null}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactFormSection;
