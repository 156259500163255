import React from "react";
import ContactFormSection from "./sections/ContactFormSection/ContactFormSection";
import classes from "./homePage.module.scss";
import AboutCompanySection from "./sections/AboutCompanySection/AboutCompanySection";
import FooterSection from "./sections/FooterSection/FooterSection";
import SuppliersPage from "./sections/SuppliersPage/SuppliersPage";
import WelcomeCanvasSection from "./sections/WelcomeCanvasSection/WelcomeCanvasSection";
import CoworkerPage from "./sections/coworkerSection/CoworkerSection";

const HomePage = ({ contactRef, servicesRef }) => {
  return (
    <div className={classes.HomePage}>
      <WelcomeCanvasSection />
      <AboutCompanySection servicesRef={servicesRef} />
      <ContactFormSection contactRef={contactRef} />
      <CoworkerPage />
      <SuppliersPage />
      <FooterSection />
    </div>
  );
};

export default HomePage;
