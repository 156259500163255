import React from "react";
import classes from "./suppliersPage.module.scss";

import supplierLogo1 from "../../../../../resources/images/suppliers/epecon-logo.png";
import supplierLogo3 from "../../../../../resources/images/suppliers/uponor_logo.svg";
import supplierLogo4 from "../../../../../resources/images/suppliers/lk_logo.jpg";
import supplierLogo6 from "../../../../../resources/images/suppliers/varmital_logo.png";

const supplierLogosLarge = [
  {
    logo: supplierLogo4,
    website: "https://www.lksystems.se/sv/",
    companyName: "LK",
  },
  {
    logo: supplierLogo1,
    website: "https://epecon.se/",
    companyName: "Epecon",
  },
];

const supplierLogosSmall = [
  // {
  //   logo: supplierLogo2,
  //   website: "https://raychem.nvent.com/",
  //   companyName: "nVent Raychem",
  // },

  {
    logo: supplierLogo3,
    website: "https://www.uponor.se/",
    companyName: "Uponor",
  },

  // {
  //   logo: supplierLogo5,
  //   website: "https://varmebaronen.se/",
  //   companyName: "Värmebaronen",
  // },

  {
    logo: supplierLogo6,
    website: "https://www.varmital.se/",
    companyName: "Varmital",
  },
];

const SuppliersPage = () => {
  return (
    <div className={classes.SuppliersPage}>
      <h2>Leverantörer</h2>

      <div className={classes.logosContainer}>
        <div
          className={classes.largeLogoContainer1}
          key={supplierLogosLarge[0].companyName}
        >
          <a href={supplierLogosLarge[0].website}>
            <img
              src={supplierLogosLarge[0].logo}
              alt={`${supplierLogosLarge[0].companyName} logo`}
            />
          </a>
        </div>
        <div
          className={classes.largeLogoContainer2}
          key={supplierLogosLarge[1].companyName}
        >
          <a href={supplierLogosLarge[1].website}>
            <img
              src={supplierLogosLarge[1].logo}
              alt={`${supplierLogosLarge[1].companyName} logo`}
            />
          </a>
        </div>
        {supplierLogosSmall.map((logoObj) => (
          <div className={classes.logoContainer} key={logoObj.companyName}>
            <a href={logoObj.website}>
              <img src={logoObj.logo} alt={`${logoObj.companyName} logo`} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuppliersPage;
