import React from "react";
import { useState } from "react";
import { useIsAway, useIsAwayUpdate } from "../../../contexts/IsAwayContext";
import { FirebaseHandler } from "../../../utility/firebaseHandler";
import SubmitButton from "../../common/form/SubmitButton/SubmitButton";
import TextInputField from "../../common/form/TextInputField/TextInputField";
import classes from "./adminPage.module.scss";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useEffect } from "react";

const AdminPage = () => {
	const isAway = useIsAway();
	const isAwayUpdate = useIsAwayUpdate();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

	const [errorMessage, setErrorMessage] = useState("");

	const submitLoginFormHandler = (event) => {
		event.preventDefault();

		handleLogin(email, password);
		setPassword("");
	};

	const handleLogin = async () => {
		const [success, message] = await FirebaseHandler.loginUser(email, password);

		if (success) {
			setUserIsLoggedIn(true);
			sessionStorage.setItem("email", email);
			localStorage.setItem("email", email);
		} else {
			setErrorMessage(message);
		}
	};

	const loggedOutContent = (
		<>
			<form onSubmit={submitLoginFormHandler}>
				<TextInputField
					title="Mail"
					text={email}
					onTextChange={setEmail}
					name="username"
					type="email"
					showOnWhiteBackground={true}
				/>

				<TextInputField
					title="Lösenord"
					text={password}
					onTextChange={setPassword}
					name="password"
					type="password"
					showOnWhiteBackground={true}
				/>

				<SubmitButton text="Logga in" />
			</form>
			<p>{errorMessage}</p>
		</>
	);
	const loggedInContent = (
		<div>
			<ToggleButtonGroup
				value={isAway}
				exclusive
				onChange={isAwayUpdate}
				style={{
					margin: "0 auto",
				}}
			>
				<ToggleButton value={false}>
					<div>Visa normala öppetider</div>
				</ToggleButton>
				<ToggleButton value={true}>
					<div>Visa att vi inte är i butiken</div>
				</ToggleButton>
			</ToggleButtonGroup>
		</div>
	);

	useEffect(() => {
		let emailFromSessionStorage = sessionStorage.getItem("email");
		let emailFromLocalStorage = localStorage.getItem("email");

		let sessionStorageExist = emailFromSessionStorage !== null;
		let localStorageExist = emailFromLocalStorage !== null;

		if (
			sessionStorageExist &&
			emailFromSessionStorage === "rasmusrudling@gmail.com"
		) {
			setUserIsLoggedIn(true);
		} else if (
			localStorageExist &&
			emailFromLocalStorage === "rasmusrudling@gmail.com"
		) {
			setUserIsLoggedIn(true);
		}
	}, []);

	return (
		<div className={classes.AdminPage}>
			{userIsLoggedIn ? loggedInContent : loggedOutContent}
		</div>
	);
};

export default AdminPage;
