import React from "react";
import classes from "./textInputField.module.scss";

const TextInputField = ({
	title,
	text,
	onTextChange,
	name,
	type = "text",
	required = true,
	showOnWhiteBackground = false,
}) => {
	return (
		<input
			type={type}
			onChange={(e) => onTextChange(e.target.value)}
			value={text}
			placeholder={title}
			className={classes.TextInputField}
			required={required}
			name={name}
			style={
				showOnWhiteBackground
					? {
							backgroundColor: "#e6e6e6",
					  }
					: null
			}
		/>
	);
};

export default TextInputField;
