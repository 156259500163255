import React from "react";
import classes from "./footerSection.module.scss";

const FooterSection = () => {
  return (
    <div className={classes.FooterSection}>
      <p>Firma Varma Golv i Solna AB</p>

      <p>
        <b>Org.nr:</b> 556512-2834
      </p>
    </div>
  );
};

export default FooterSection;
