import React from "react";
import classes from "./aboutCompanySection.module.scss";

import image1 from "../../../../../resources/images/photos_optimized/ludde_installation.png";
import image2 from "../../../../../resources/images/photos_optimized/customer_meeting.jpg";
import image3 from "../../../../../resources/images/photos_optimized/skiss.png";
import image4 from "../../../../../resources/images/photos_optimized/tools.jpg";

const AboutCompanySection = ({ servicesRef }) => {
  return (
    <div className={classes.AboutCompanySection}>
      <div className={classes.initInfo}>
        <p>
          Välkommen till oss på Varma Golv! Vi är experter på vattenburen
          golvvärme och radiatorer och är ett väletablerat företag som startade
          vår verksamhet 1994. Varma Golv erbjuder såväl professionell
          konsultation som ett stort utbud av golvvärmeprodukter av hög
          kvalitet. Vi kan hjälpa dig hela vägen från idé till installation.
        </p>
      </div>

      <div className={classes.infoContainer} ref={servicesRef}>
        <div className={classes.imageContainer}>
          <img src={image1} alt="Room with nice floor" />
        </div>

        <div>
          <h2>Krånglar din golvvärme?</h2>
          <p>
            Varma Golv hjälper till med felsökning av din golvvärme och ger dig
            förslag på åtgärder. Vi har lång erfarenhet och en god kunskap om de
            flesta vattenburna golvvärmesystem. Vanliga problem med system som
            har några år på nacken är att rumsregleringen behöver uppdateras
            eller att ventiler behöver bytas ut. Vi hjälper dig snabbt och
            smidigt så att du får det varmt och skönt igen!
          </p>
        </div>
      </div>

      <div className={classes.infoContainer}>
        <div className={classes.imageContainer}>
          <img src={image2} alt="Room with nice floor" />
        </div>

        <div>
          <h2>Vill du köpa material?</h2>
          <p>
            I vår butik i Sundbyberg kan du köpa produkter av hög kvalitet från
            bland annat Epecon, Uponor och LK-systems. Finns inte de produkter
            du behöver i vårt lager kan vi beställa det som passar just dina
            behov. Får du material över har du alltid full returrätt. Kontakta
            oss så berättar vi mer om utbud och leveransmöjligheter!
          </p>
        </div>
      </div>

      <div className={classes.infoContainer}>
        <div className={classes.imageContainer}>
          <img src={image3} alt="Room with nice floor" />
        </div>

        <div>
          <h2>Behöver du hjälp med materialberäkning och ritningar?</h2>
          <p>
            Med många års erfarenhet inom golvvärme kan vi på Varma Golv med
            trygghet erbjuda dig en professionell konsultation för just ditt
            projekt. Köper du materialet hos oss erbjuder vi dig hjälp med
            slingritningar, injusteringsvärden och val av korrekt bjälklagstyp.
          </p>
        </div>
      </div>

      <div className={classes.infoContainer}>
        <div className={classes.imageContainer}>
          <img src={image4} alt="Room with nice floor" />
        </div>
        <div>
          <h2>Saknar du rätt verktyg?</h2>
          <p>
            För en smidig golvvärmeförläggning med bra resultat krävs rätt
            verktyg! Hos oss på Varma Golv hittar du allt du behöver. Vi
            erbjuder uthyrning av bland annat slangvinda, överhandsfräs och
            provtryckningsutrustning. Hör av dig eller kom förbi butiken om du
            har en särskild förfrågan på utrustning eller vill ha en offert.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutCompanySection;
