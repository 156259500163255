import "./app.scss";
import Navigation from "./components/common/Navigation/Navigation";
import HomePage from "./components/pages/Homepage/Homepage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AdminPage from "./components/pages/AdminPage/AdminPage";
import IsAwayProvider from "./contexts/IsAwayContext";
import { useRef } from "react";

const navigationButtons = [
	{
		pageAddress: "test",
		text: "Tjänster",
	},
	{
		pageAddress: "test",
		text: "Kontakt",
	},
];

const App = () => {
	const contactRef = useRef(null);
	const servicesRef = useRef(null);

	return (
		<IsAwayProvider>
			<Router>
				<Switch>
					<Route exact path="/">
						<Navigation
							navigationButtons={navigationButtons}
							contactRef={contactRef}
							servicesRef={servicesRef}
						/>
						<HomePage contactRef={contactRef} servicesRef={servicesRef} />
					</Route>

					<Route exact path="/admin">
						<Navigation navigationButtons={navigationButtons} />
						<AdminPage />
					</Route>
				</Switch>
			</Router>
		</IsAwayProvider>
	);
};

export default App;
