import React from "react";
import classes from "./desktopNavigation.module.scss";
import varmaGolvLogo from "../../../../resources/images/logos/varma_golv_logo.svg";
import { useHistory } from "react-router-dom";

const DesktopNavigation = ({
  navigationButtons,
  navigationButtonClickHandler,
}) => {
  const history = useHistory();
  return (
    <div className={classes.DesktopNavigation}>
      <img
        src={varmaGolvLogo}
        alt="Varma Golv logo"
        onClick={() => {
          history.replace("/");
          window.scrollTo(0, 0);
        }}
      />

      <div className={classes.buttonsContainer}>
        {navigationButtons.map((navigationButton) => (
          <button
            key={navigationButton.text}
            onClick={() => navigationButtonClickHandler(navigationButton.text)}
          >
            {navigationButton.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DesktopNavigation;
