import React from "react";
import classes from "./welcomeCanvasSection.module.scss";
import jorgenImg from "../../../../../resources/images/photos_optimized/welcome.png";

const WelcomeCanvasSection = () => {
  return (
    <div className={classes.WelcomeCanvasSection}>
      <img src={jorgenImg} className={classes.Image} alt={"Jörgen"} />
      <h1 className={classes.ImageText}>
        Det lilla företaget med <br />
        det stora kunnandet
      </h1>
    </div>
  );
};

export default WelcomeCanvasSection;
