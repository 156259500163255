import React from "react";
import classes from "./textArea.module.scss";

const TextArea = ({
  title,
  text,
  onTextChange,
  name,
  type = "text",
  required = true,
}) => {
  return (
    <textarea
      type={type}
      onChange={(e) => onTextChange(e.target.value)}
      value={text}
      placeholder={title}
      className={classes.TextArea}
      required={required}
      name={name}
    />
  );
};

export default TextArea;
