import React from "react";
import classes from "./navigation.module.scss";

import DesktopNavigation from "./DesktopNavigation/DesktopNavigation";
import MobileNavigation from "./MobileNavigation/MobileNavigation";

const Navigation = ({ navigationButtons, contactRef, servicesRef }) => {
  const navigationButtonClickHandler = (buttonText) => {
    console.log(buttonText);
    if (buttonText === "Kontakt") {
      const yOffset = -50;
      const element = contactRef.current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    } else if (buttonText === "Tjänster") {
      const yOffset = -70;
      const element = servicesRef.current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <div className={classes.Navigation}>
      <div className={classes.infoBar}>
        <p>
          Kontakta oss på{" "}
          <a href="mailto:info@varmagolv.se">info@varmagolv.se</a> eller{" "}
          <a href="tel:0707152072">070-715 20 72</a>
        </p>
      </div>
      <div className={classes.desktopContainer}>
        <DesktopNavigation
          navigationButtons={navigationButtons}
          navigationButtonClickHandler={navigationButtonClickHandler}
        />
      </div>

      <div className={classes.mobileContainer}>
        <MobileNavigation
          navigationButtons={navigationButtons}
          navigationButtonClickHandler={navigationButtonClickHandler}
        />
      </div>
    </div>
  );
};

export default Navigation;
