import React from "react";
import classes from "./submitButton.module.scss";

const SubmitButton = ({ text }) => {
  return (
    <button className={classes.SubmitButton} type="submit" value="submit">
      {text}
    </button>
  );
};

export default SubmitButton;
