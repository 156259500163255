import React from "react";
import classes from "./coworkerSection.module.scss";

import ludde from "../../../../../resources/images/photos_optimized/ludde_portrait.jpg";
import jorgen from "../../../../../resources/images/photos_optimized/jorgen_portrait.jpg";

const CoworkerPage = () => {
  return (
    <div className={classes.SuppliersPage}>
      <h2>Medarbetare</h2>

      <div className={classes.logosContainer}>
        <div className={classes.logoContainer} key={"coworker2"}>
          <img src={jorgen} alt={`${""} logo`} />
          <h4 className={classes.coworkerName}>Jörgen Larsson</h4>
          <span className={classes.coworkerTitle}>VD</span>
        </div>
        <div className={classes.logoContainer} key={"coworker1"}>
          <img src={ludde} alt={`${""} logo`} />
          <h4 className={classes.coworkerName}>Ludvig Larsson</h4>
          <span className={classes.coworkerTitle}>Installatör</span>
        </div>
      </div>
    </div>
  );
};

export default CoworkerPage;
